// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as SortOrder from "../../scalars/SortOrder.mjs";
import * as Timestamp from "../../scalars/Timestamp.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as Belt_HashMapString from "rescript/lib/es6/belt_HashMapString.js";
import * as SiteStatusSortOrder from "../../scalars/SiteStatusSortOrder.mjs";
import * as RelayRouter__Bindings from "rescript-relay-router/src/RelayRouter__Bindings.mjs";
import * as SearchViewSortOptions from "../../scalars/SearchViewSortOptions.mjs";
import * as TranscriptionSortOrder from "../../scalars/TranscriptionSortOrder.mjs";
import * as SiteStatusSortAttribute from "../../scalars/SiteStatusSortAttribute.mjs";
import * as TranscriptionSortAttribute from "../../scalars/TranscriptionSortAttribute.mjs";
import * as OrganizationMembershipSortAttribute from "../../scalars/OrganizationMembershipSortAttribute.mjs";
import * as RelayRouter__Internal__DeclarationsSupport from "rescript-relay-router/src/RelayRouter__Internal__DeclarationsSupport.mjs";

var loadedRouteRenderers = Belt_HashMapString.make(24);

function make(prepareDisposeTimeoutOpt) {
  var prepareDisposeTimeout = prepareDisposeTimeoutOpt !== undefined ? prepareDisposeTimeoutOpt : 300000;
  var match = RelayRouter__Internal__DeclarationsSupport.makePrepareAssets(loadedRouteRenderers, prepareDisposeTimeout);
  var prepareRoute = match.prepareRoute;
  var getPrepared = match.getPrepared;
  var routeName = "Root";
  var loadRouteRenderer = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName, loadedRouteRenderers);
  };
  var makePrepareProps = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$1 = "Root__Dashboard";
  var loadRouteRenderer$1 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Dashboard_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$1, loadedRouteRenderers);
  };
  var makePrepareProps$1 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$2 = "Root__CoEditor";
  var loadRouteRenderer$2 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__CoEditor_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$2, loadedRouteRenderers);
  };
  var makePrepareProps$2 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$3 = "Root__CoEditor__CitationStory";
  var loadRouteRenderer$3 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__CoEditor__CitationStory_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$3, loadedRouteRenderers);
  };
  var makePrepareProps$3 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            mode: pathParams["mode"],
            version: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "version"), Belt_Int.fromString),
            group: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "group"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$4 = "Root__CoEditor__NewCitationStory";
  var loadRouteRenderer$4 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__CoEditor__NewCitationStory_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$4, loadedRouteRenderers);
  };
  var makePrepareProps$4 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            fromRadarArticleId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "fromRadarArticleId"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$5 = "Root__AdvancedSearch";
  var loadRouteRenderer$5 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AdvancedSearch_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$5, loadedRouteRenderers);
  };
  var makePrepareProps$5 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$6 = "Root__AdvancedSearch__Radar";
  var loadRouteRenderer$6 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AdvancedSearch__Radar_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$6, loadedRouteRenderers);
  };
  var makePrepareProps$6 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            sort: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), (function (value) {
                    return SearchViewSortOptions.parse(decodeURIComponent(value));
                  })),
            showHidden: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  })),
            childParams: pathParams
          };
  };
  var routeName$7 = "Root__AdvancedSearch__Radar__SelectedArticle";
  var loadRouteRenderer$7 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AdvancedSearch__Radar__SelectedArticle_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$7, loadedRouteRenderers);
  };
  var makePrepareProps$7 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            sort: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), (function (value) {
                    return SearchViewSortOptions.parse(decodeURIComponent(value));
                  })),
            showHidden: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  }))
          };
  };
  var routeName$8 = "Root__AdvancedSearch__ContentExchange";
  var loadRouteRenderer$8 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AdvancedSearch__ContentExchange_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$8, loadedRouteRenderers);
  };
  var makePrepareProps$8 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            sort: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), (function (value) {
                    return SearchViewSortOptions.parse(decodeURIComponent(value));
                  })),
            showHidden: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  })),
            childParams: pathParams
          };
  };
  var routeName$9 = "Root__AdvancedSearch__ContentExchange__ContentExchangeSelectedArticle";
  var loadRouteRenderer$9 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AdvancedSearch__ContentExchange__ContentExchangeSelectedArticle_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$9, loadedRouteRenderers);
  };
  var makePrepareProps$9 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            sort: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), (function (value) {
                    return SearchViewSortOptions.parse(decodeURIComponent(value));
                  })),
            showHidden: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  }))
          };
  };
  var routeName$10 = "Root__UniversalSearch";
  var loadRouteRenderer$10 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__UniversalSearch_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$10, loadedRouteRenderers);
  };
  var makePrepareProps$10 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            selectedItem: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItem"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            filterOpen: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "filterOpen"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  }))
          };
  };
  var routeName$11 = "Root__AiChat";
  var loadRouteRenderer$11 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AiChat_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$11, loadedRouteRenderers);
  };
  var makePrepareProps$11 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            selectedTemplateId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedTemplateId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            childParams: pathParams
          };
  };
  var routeName$12 = "Root__AiChat__Conversation";
  var loadRouteRenderer$12 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AiChat__Conversation_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$12, loadedRouteRenderers);
  };
  var makePrepareProps$12 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            selectedTemplateId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedTemplateId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$13 = "Root__TemplateManagement";
  var loadRouteRenderer$13 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__TemplateManagement_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$13, loadedRouteRenderers);
  };
  var makePrepareProps$13 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            selectedTemplateId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedTemplateId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$14 = "Root__TranscriptionsOverview";
  var loadRouteRenderer$14 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__TranscriptionsOverview_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$14, loadedRouteRenderers);
  };
  var makePrepareProps$14 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            sortOrder: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), (function (value) {
                    return TranscriptionSortOrder.parse(decodeURIComponent(value));
                  })),
            sortAttribute: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), (function (value) {
                    return TranscriptionSortAttribute.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$15 = "Root__TranscriptionsOverview__NewTranscription";
  var loadRouteRenderer$15 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__TranscriptionsOverview__NewTranscription_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$15, loadedRouteRenderers);
  };
  var makePrepareProps$15 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            sortOrder: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), (function (value) {
                    return TranscriptionSortOrder.parse(decodeURIComponent(value));
                  })),
            sortAttribute: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), (function (value) {
                    return TranscriptionSortAttribute.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$16 = "Root__Transcription";
  var loadRouteRenderer$16 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Transcription_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$16, loadedRouteRenderers);
  };
  var makePrepareProps$16 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            timestamp: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "timestamp"), (function (value) {
                    return Timestamp.parse(decodeURIComponent(value));
                  })),
            childParams: pathParams
          };
  };
  var routeName$17 = "Root__Transcription__TranscriptionImproved";
  var loadRouteRenderer$17 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Transcription__TranscriptionImproved_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$17, loadedRouteRenderers);
  };
  var makePrepareProps$17 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            timestamp: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "timestamp"), (function (value) {
                    return Timestamp.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$18 = "Root__Settings";
  var loadRouteRenderer$18 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Settings_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$18, loadedRouteRenderers);
  };
  var makePrepareProps$18 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$19 = "Root__Settings__Teams";
  var loadRouteRenderer$19 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Settings__Teams_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$19, loadedRouteRenderers);
  };
  var makePrepareProps$19 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$20 = "Root__Settings__Teams__Team";
  var loadRouteRenderer$20 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Settings__Teams__Team_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$20, loadedRouteRenderers);
  };
  var makePrepareProps$20 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            slug: pathParams["slug"],
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            sortAttribute: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), (function (value) {
                    return OrganizationMembershipSortAttribute.parse(decodeURIComponent(value));
                  })),
            sortOrder: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), (function (value) {
                    return SortOrder.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$21 = "Root__RadarStatusDashboard";
  var loadRouteRenderer$21 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__RadarStatusDashboard_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$21, loadedRouteRenderers);
  };
  var makePrepareProps$21 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            sortOrder: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), (function (value) {
                    return SiteStatusSortOrder.parse(decodeURIComponent(value));
                  })),
            sortAttribute: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), (function (value) {
                    return SiteStatusSortAttribute.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$22 = "Root__FeaturebaseRedirect";
  var loadRouteRenderer$22 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__FeaturebaseRedirect_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$22, loadedRouteRenderers);
  };
  var makePrepareProps$22 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$23 = "Root__NotFound";
  var loadRouteRenderer$23 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__NotFound_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$23, loadedRouteRenderers);
  };
  var makePrepareProps$23 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  return [{
            path: "/",
            name: routeName,
            __$rescriptChunkName__: "Root_route_renderer",
            loadRouteRenderer: loadRouteRenderer,
            preloadCode: (function (environment, pathParams, queryParams, $$location) {
                return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName, loadRouteRenderer, makePrepareProps, environment, pathParams, queryParams, $$location);
              }),
            prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps, (function (pathParams, queryParams) {
                              return "Root:";
                            }), getPrepared, routeName, loadRouteRenderer, intent);
              }),
            children: [
              {
                path: "",
                name: routeName$1,
                __$rescriptChunkName__: "Root__Dashboard_route_renderer",
                loadRouteRenderer: loadRouteRenderer$1,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$1, loadRouteRenderer$1, makePrepareProps$1, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$1, (function (pathParams, queryParams) {
                                  return "Root__Dashboard:";
                                }), getPrepared, routeName$1, loadRouteRenderer$1, intent);
                  }),
                children: []
              },
              {
                path: "co-editor",
                name: routeName$2,
                __$rescriptChunkName__: "Root__CoEditor_route_renderer",
                loadRouteRenderer: loadRouteRenderer$2,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$2, loadRouteRenderer$2, makePrepareProps$2, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$2, (function (pathParams, queryParams) {
                                  return "Root__CoEditor:";
                                }), getPrepared, routeName$2, loadRouteRenderer$2, intent);
                  }),
                children: [
                  {
                    path: "citation-story/:id/:mode(inputs|body|suggestions|fullstory)",
                    name: routeName$3,
                    __$rescriptChunkName__: "Root__CoEditor__CitationStory_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$3,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$3, loadRouteRenderer$3, makePrepareProps$3, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$3, (function (pathParams, queryParams) {
                                      return "Root__CoEditor__CitationStory:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(Js_dict.get(pathParams, "mode"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "version"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "group"), "");
                                    }), getPrepared, routeName$3, loadRouteRenderer$3, intent);
                      }),
                    children: []
                  },
                  {
                    path: "citation-story/new",
                    name: routeName$4,
                    __$rescriptChunkName__: "Root__CoEditor__NewCitationStory_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$4,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$4, loadRouteRenderer$4, makePrepareProps$4, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$4, (function (pathParams, queryParams) {
                                      return "Root__CoEditor__NewCitationStory:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "fromRadarArticleId"), "");
                                    }), getPrepared, routeName$4, loadRouteRenderer$4, intent);
                      }),
                    children: []
                  }
                ]
              },
              {
                path: "",
                name: routeName$5,
                __$rescriptChunkName__: "Root__AdvancedSearch_route_renderer",
                loadRouteRenderer: loadRouteRenderer$5,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$5, loadRouteRenderer$5, makePrepareProps$5, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$5, (function (pathParams, queryParams) {
                                  return "Root__AdvancedSearch:";
                                }), getPrepared, routeName$5, loadRouteRenderer$5, intent);
                  }),
                children: [
                  {
                    path: "radar",
                    name: routeName$6,
                    __$rescriptChunkName__: "Root__AdvancedSearch__Radar_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$6,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$6, loadRouteRenderer$6, makePrepareProps$6, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$6, (function (pathParams, queryParams) {
                                      return "Root__AdvancedSearch__Radar:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), "");
                                    }), getPrepared, routeName$6, loadRouteRenderer$6, intent);
                      }),
                    children: [{
                        path: ":id",
                        name: routeName$7,
                        __$rescriptChunkName__: "Root__AdvancedSearch__Radar__SelectedArticle_route_renderer",
                        loadRouteRenderer: loadRouteRenderer$7,
                        preloadCode: (function (environment, pathParams, queryParams, $$location) {
                            return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$7, loadRouteRenderer$7, makePrepareProps$7, environment, pathParams, queryParams, $$location);
                          }),
                        prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                            return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$7, (function (pathParams, queryParams) {
                                          return "Root__AdvancedSearch__Radar__SelectedArticle:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), "");
                                        }), getPrepared, routeName$7, loadRouteRenderer$7, intent);
                          }),
                        children: []
                      }]
                  },
                  {
                    path: "content-exchange",
                    name: routeName$8,
                    __$rescriptChunkName__: "Root__AdvancedSearch__ContentExchange_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$8,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$8, loadRouteRenderer$8, makePrepareProps$8, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$8, (function (pathParams, queryParams) {
                                      return "Root__AdvancedSearch__ContentExchange:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), "");
                                    }), getPrepared, routeName$8, loadRouteRenderer$8, intent);
                      }),
                    children: [{
                        path: ":id",
                        name: routeName$9,
                        __$rescriptChunkName__: "Root__AdvancedSearch__ContentExchange__ContentExchangeSelectedArticle_route_renderer",
                        loadRouteRenderer: loadRouteRenderer$9,
                        preloadCode: (function (environment, pathParams, queryParams, $$location) {
                            return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$9, loadRouteRenderer$9, makePrepareProps$9, environment, pathParams, queryParams, $$location);
                          }),
                        prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                            return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$9, (function (pathParams, queryParams) {
                                          return "Root__AdvancedSearch__ContentExchange__ContentExchangeSelectedArticle:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "showHidden"), "");
                                        }), getPrepared, routeName$9, loadRouteRenderer$9, intent);
                          }),
                        children: []
                      }]
                  }
                ]
              },
              {
                path: "universal-search",
                name: routeName$10,
                __$rescriptChunkName__: "Root__UniversalSearch_route_renderer",
                loadRouteRenderer: loadRouteRenderer$10,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$10, loadRouteRenderer$10, makePrepareProps$10, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$10, (function (pathParams, queryParams) {
                                  return "Root__UniversalSearch:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItem"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "filterOpen"), "");
                                }), getPrepared, routeName$10, loadRouteRenderer$10, intent);
                  }),
                children: []
              },
              {
                path: "chat/ai-chat",
                name: routeName$11,
                __$rescriptChunkName__: "Root__AiChat_route_renderer",
                loadRouteRenderer: loadRouteRenderer$11,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$11, loadRouteRenderer$11, makePrepareProps$11, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$11, (function (pathParams, queryParams) {
                                  return "Root__AiChat:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedTemplateId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "");
                                }), getPrepared, routeName$11, loadRouteRenderer$11, intent);
                  }),
                children: [{
                    path: ":id",
                    name: routeName$12,
                    __$rescriptChunkName__: "Root__AiChat__Conversation_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$12,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$12, loadRouteRenderer$12, makePrepareProps$12, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$12, (function (pathParams, queryParams) {
                                      return "Root__AiChat__Conversation:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedTemplateId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "");
                                    }), getPrepared, routeName$12, loadRouteRenderer$12, intent);
                      }),
                    children: []
                  }]
              },
              {
                path: "chat/template-management",
                name: routeName$13,
                __$rescriptChunkName__: "Root__TemplateManagement_route_renderer",
                loadRouteRenderer: loadRouteRenderer$13,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$13, loadRouteRenderer$13, makePrepareProps$13, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$13, (function (pathParams, queryParams) {
                                  return "Root__TemplateManagement:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedTemplateId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "");
                                }), getPrepared, routeName$13, loadRouteRenderer$13, intent);
                  }),
                children: []
              },
              {
                path: "transcriptions",
                name: routeName$14,
                __$rescriptChunkName__: "Root__TranscriptionsOverview_route_renderer",
                loadRouteRenderer: loadRouteRenderer$14,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$14, loadRouteRenderer$14, makePrepareProps$14, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$14, (function (pathParams, queryParams) {
                                  return "Root__TranscriptionsOverview:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), "");
                                }), getPrepared, routeName$14, loadRouteRenderer$14, intent);
                  }),
                children: [{
                    path: "new",
                    name: routeName$15,
                    __$rescriptChunkName__: "Root__TranscriptionsOverview__NewTranscription_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$15,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$15, loadRouteRenderer$15, makePrepareProps$15, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$15, (function (pathParams, queryParams) {
                                      return "Root__TranscriptionsOverview__NewTranscription:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), "");
                                    }), getPrepared, routeName$15, loadRouteRenderer$15, intent);
                      }),
                    children: []
                  }]
              },
              {
                path: "/transcriptions/:id",
                name: routeName$16,
                __$rescriptChunkName__: "Root__Transcription_route_renderer",
                loadRouteRenderer: loadRouteRenderer$16,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$16, loadRouteRenderer$16, makePrepareProps$16, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$16, (function (pathParams, queryParams) {
                                  return "Root__Transcription:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "timestamp"), "");
                                }), getPrepared, routeName$16, loadRouteRenderer$16, intent);
                  }),
                children: [{
                    path: "improved",
                    name: routeName$17,
                    __$rescriptChunkName__: "Root__Transcription__TranscriptionImproved_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$17,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$17, loadRouteRenderer$17, makePrepareProps$17, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$17, (function (pathParams, queryParams) {
                                      return "Root__Transcription__TranscriptionImproved:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "timestamp"), "");
                                    }), getPrepared, routeName$17, loadRouteRenderer$17, intent);
                      }),
                    children: []
                  }]
              },
              {
                path: "settings",
                name: routeName$18,
                __$rescriptChunkName__: "Root__Settings_route_renderer",
                loadRouteRenderer: loadRouteRenderer$18,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$18, loadRouteRenderer$18, makePrepareProps$18, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$18, (function (pathParams, queryParams) {
                                  return "Root__Settings:";
                                }), getPrepared, routeName$18, loadRouteRenderer$18, intent);
                  }),
                children: [{
                    path: "teams",
                    name: routeName$19,
                    __$rescriptChunkName__: "Root__Settings__Teams_route_renderer",
                    loadRouteRenderer: loadRouteRenderer$19,
                    preloadCode: (function (environment, pathParams, queryParams, $$location) {
                        return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$19, loadRouteRenderer$19, makePrepareProps$19, environment, pathParams, queryParams, $$location);
                      }),
                    prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                        return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$19, (function (pathParams, queryParams) {
                                      return "Root__Settings__Teams:";
                                    }), getPrepared, routeName$19, loadRouteRenderer$19, intent);
                      }),
                    children: [{
                        path: ":slug",
                        name: routeName$20,
                        __$rescriptChunkName__: "Root__Settings__Teams__Team_route_renderer",
                        loadRouteRenderer: loadRouteRenderer$20,
                        preloadCode: (function (environment, pathParams, queryParams, $$location) {
                            return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$20, loadRouteRenderer$20, makePrepareProps$20, environment, pathParams, queryParams, $$location);
                          }),
                        prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                            return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$20, (function (pathParams, queryParams) {
                                          return "Root__Settings__Teams__Team:" + Core__Option.getOr(Js_dict.get(pathParams, "slug"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), "");
                                        }), getPrepared, routeName$20, loadRouteRenderer$20, intent);
                          }),
                        children: []
                      }]
                  }]
              },
              {
                path: "status/scrapers",
                name: routeName$21,
                __$rescriptChunkName__: "Root__RadarStatusDashboard_route_renderer",
                loadRouteRenderer: loadRouteRenderer$21,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$21, loadRouteRenderer$21, makePrepareProps$21, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$21, (function (pathParams, queryParams) {
                                  return "Root__RadarStatusDashboard:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), "");
                                }), getPrepared, routeName$21, loadRouteRenderer$21, intent);
                  }),
                children: []
              },
              {
                path: "featurebase-login",
                name: routeName$22,
                __$rescriptChunkName__: "Root__FeaturebaseRedirect_route_renderer",
                loadRouteRenderer: loadRouteRenderer$22,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$22, loadRouteRenderer$22, makePrepareProps$22, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$22, (function (pathParams, queryParams) {
                                  return "Root__FeaturebaseRedirect:";
                                }), getPrepared, routeName$22, loadRouteRenderer$22, intent);
                  }),
                children: []
              },
              {
                path: "*",
                name: routeName$23,
                __$rescriptChunkName__: "Root__NotFound_route_renderer",
                loadRouteRenderer: loadRouteRenderer$23,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$23, loadRouteRenderer$23, makePrepareProps$23, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$23, (function (pathParams, queryParams) {
                                  return "Root__NotFound:";
                                }), getPrepared, routeName$23, loadRouteRenderer$23, intent);
                  }),
                children: []
              }
            ]
          }];
}

export {
  make ,
}
/* loadedRouteRenderers Not a pure module */
